* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

input,
button,
textarea {
  font-family: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

em {
  font-style: normal !important;
}

html,
body {
  height: 100%;
  font-family: "PingFang SC", BlinkMacSystemFont, Roboto, "Helvetica Neue",
    sans-serif;
}

//@font-face{
//  font-family: 'ChenJiShi';
//  src:url('https://images.redream.cn/upic/2019/20220102220251-ChenJiShi-YingBiXingShu130106-2.ttf') format('truetype');
//}

.el-message__icon {
  display: none;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.web-title {
  margin: 0 15px 0 5px;
}

.web-icon {
  width: auto;
  height: 1.5rem;
  vertical-align: middle;
}

#editor {
  height: 100%;
  display: block;
  border: none;
  width: 100%;
  padding: 10px;
}

section {
  height: 100%;
}

.main-body {
  display: flex;
  flex-direction: column;
  padding-top: 0;
}

.ctrl {
  flex-basis: 60px;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
}

.preview-wrapper {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  padding: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  word-break: break-all;
  overflow-y: scroll;
}

.main-section {
  display: flex;
  height: 100%;
}

.hint {
  opacity: 0.6;
  margin: 20px 0;
}

.preview {
  position: relative;
  margin: 0 -20px;
  width: 375px;
  padding: 20px;
  font-size: 14px;
  box-sizing: border-box;
  outline: none;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
}

.preview table {
  margin-bottom: 10px;
  border-collapse: collapse;
  display: table;
  width: 100% !important;
}

/* ele ui */
.el-form-item {
  margin-bottom: 0 !important;
}

.el-tooltip {
  cursor: pointer;
}

uni-page-body,
uni-page-refresh {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
