
/deep/ .el-dialog {
  min-width: 480px;
}

.link{
  margin-right: 10px;
  color: #409EFF;

}
