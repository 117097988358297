
.editor__header {
  width: 100%;
}
.header__item {
  margin: 0 3px;
}
.header__item_last {
  margin-right: 8px;
}
.header__switch {
  margin-left: 8px;
}
.mode__switch {
  margin-left: 24px;
  margin-right: 24px;
  width: 24px;
  height: 24px;
  background: url("../../assets/images/night.png") no-repeat;
  background-size: cover;
  transition: all 0.3s;
}
.mode__switch_black {
  background: url("../../assets/images/light.png") no-repeat;
  background-size: cover;
}
.top {
  height: 60px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  margin-right: 0;
}
.el-select {
  margin-right: 12px;
}
.left-side {
  display: flex;
  align-items: center;
  flex: 1;
}
.right-side {
  display: flex;
  align-items: center;
}

/*
.preview table tr:nth-child(even){
  background: rgb(250, 250, 250);
}
 */
.select-item-left {
  float: left;
}

.select-item-right {
  float: right;
  color: #8492a6;
  font-size: 13px;
}
.link{
  margin-right: 10px;
  color: #409EFF;

}
